@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");


.date-input {
    width: 100%;
    padding: 10px 0px 10px 10px; /* Chừa khoảng trống bên phải cho icon */
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  
    /* Thêm icon lịch vào input */
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23666666" viewBox="0 0 24 24"%3E%3Cpath d="M7 10h5v5H7zm6 0h4v5h-4zm0 6h4v4h-4zm-6 0h5v4H7zm9-9h5V4h-5V3h-2v1H9V3H7v1H2v2h20V7H7zm-9-2v4h5V5zm11 2h4v4h-4V7zm-6 2H7V7h5v2zm1 7h4v4h-4v-4zm-6 0h5v4H7v-4zm13 0h4v4h-4v-4z"/%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 20px;
  }
  
  .date-input:focus {
    border-color: #007bff;
    outline: none;
  }
/* datepicker styles */

.datepicker-container {
    position: relative;
}
.btnConfirm-datepicker{
    color: rgb(0, 101, 196);
}
.datepicker {
    position: absolute;
    top: 100%;
    z-index: 100;
    margin-top: 2px;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
}

.datepicker button {
    cursor: pointer;
    border: none;
    border-radius: 3px;
    background: transparent;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    transition: 0.3s;
    touch-action: manipulation;
}

.days,
.dates {
    display: grid;
    grid-template-columns: repeat(7, 32px);
    gap: 10px;
    margin-block: 10px;
}

.days span {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
}

.dates button {
    color: slategray;
    aspect-ratio: 1;
}

.dates button:disabled {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
}

.dates button:hover {
    background: rgba(119, 136, 153, 0.1);
}

.dates button.today {
    background: rgba(147, 112, 216, 0.2);
    color: rgb(34, 137, 255);
}

.dates button.selected {
    background: rgb(55, 165, 255);
    color: #fff;
}

.datepicker-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}

.datepicker-header select,
.datepicker-header input {
    font-size: 14px;
    border: 1px solid #eee;
}

.datepicker-header input {
    max-width: 64px;
}

.datepicker-header button {
    color: slategray;
}

.datepicker-header button:hover {
    color: rgb(34, 137, 255);
}

.datepicker-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding-top: 10px;
    border-top: 1px solid #eee;
}

.datepicker-footer button {
    background: rgba(119, 136, 153, 0.1);
    padding: 3px 10px;
}

.datepicker-footer button.apply {
    background: rgb(34, 137, 255);
    color: #fff;
}