.footer {
  background-color: #25264a;
  color: #fff;
  text-align: center;
  margin-top: auto;
  padding: 20px 0;
  bottom: 0;
  width: 100%;

  .footer-support {
    h3 {
      margin-bottom: 20px;
      color: #fff;
    }

    .support-channels {
      justify-content: center;
      // flex-wrap: wrap;
      gap: 20px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      padding-left: 10%;
      padding-right: 10%;
      div {
        padding: 25px 10px;
        border-radius: 8px;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          background-color: #c3ebff;
          color: #000000;
          transform: translateY(-5px);
        }

        p {
          font-size: 14px;
          margin-bottom: 10px;
          flex-wrap: wrap;

        }

        img {
          width: 50px;
          height: 50px;
          transition: transform 0.3s ease;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }

    .contact-info {
      margin-top: 20px;
      font-size: 14px;

      a {
        color: #00aaff;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .footer-bottom {
    margin-top: 20px;
    border-top: 1px solid #444;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;

    p {
      margin: 5px 0;
      font-size: 12px;
      justify-items: center;

      a {
        color: #00aaff;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .social-icons {
      display: flex;
      justify-content: right;
      float: right;
      gap: 10px;
      padding-right: 150px;

      img {
        font-size: 18px;
        cursor: pointer;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.2);
        }
      }

      .fab {
        filter: grayscale(100%);
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .footer-bottom {
    display: block !important;
    margin-top: 20px;
    border-top: 1px solid #444;
    padding-top: 10px;
  }
  .fab {
    filter: grayscale(100%);
    width: 30px;
    height: 30px;
  }
}
