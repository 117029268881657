.blog-list-container {
  padding-left: 20%;
  padding-right: 20%;
  width: 100%;
}

.blog-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  width: 100%;

}

.blog-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background-color: #f9f9f9;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 250px; /* Đặt chiều cao cố định cho các thẻ */
}

.blog-item img {
  max-width: 100%;
  border-radius: 8px;
  height: 100%; /* Đặt chiều cao cho hình ảnh để kích thước đồng nhất */
  object-fit: cover; /* Đảm bảo hình ảnh không bị méo */
}

.blog-title {
  font-size: 14px;
  margin-top: auto;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media screen and (max-width: 768px) {
  .blog-list-container {
    padding-left: 5%;
    padding-right:5%;
    width: 100%;
  }
  
}