.notifications-dropdown {
    position: absolute;
    top: 50px;
    right: 10px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }
  
  .notifications-header {
    padding: 10px;
    max-height: 400px;
    overflow-y: auto;
  }
  
  .notification-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
  }
  .notification-icon {
    color: #0076e4;
  }
  .notification-item:hover {
    background-color: #f9f9f9;
  }
  
  .notification-title {
    font-size: 14px;
    font-weight: bold;
  }
  
  .notification-date {
    font-size: 12px;
    color: gray;
  }
  
  .view-all-btn {
    background: none;
    border: none;
    color: #007bff;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .view-all-btn:hover {
    text-decoration: underline;
  }
  