  .dropdown {
      width: 40%;
      height: 100%;
      border-radius: 10px;
      font-family: var(--font-family);
      border: 2px solid #ccc;
      cursor: pointer;
      position: relative;
  }
  
  .dropdown * {
      box-sizing: border-box;
  }
  
  .select {
      background: #ffffff;
      color: #000000;
      width: 100%;
      height: 100%;
      display: flex;
      border-radius: 10px;
      justify-content: space-between;
      align-items: center;
      padding: 1em;
      cursor: pointer;
  }
  
  .selected {
      font-size: 15px;
      margin-left: 5px;
  }
  
  .select-clicked {
      border: 2px #26489a solid;
      box-shadow: 0 0 0.8em 0 #26489a;
  }
  
  .select:hover {
      background: #e6e6e6;
  }
  
  .caret {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid #0a0a0a;
      transition: 0.3s;
  }
  
  .carte-rotate {
      transform: rotate(180deg);
  }
  
  .menu {
      list-style: none;
      padding: 0.2em 0.5em;
      border: 1px var(--blue) solid;
      border-radius: 0.5em;
      background: var(--blue);
      color: #ffffff;
      position: absolute;
      transition: 0.2s;
      box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
      top: 3em;
      left: 50%;
      width: 100%;
      transform: translateX(-50%);
      z-index: 100;
      display: none;
  }
  
  .menu li {
      padding: 0.7em 0.5em;
      cursor: pointer;
      margin: 0.3em 0;
      border-radius: 0.5em;
  }
  
  .menu.show {
      display: block;
  }
  
  .menu li:hover {
      background: #ffffff;
      color: var(--blue);
  }