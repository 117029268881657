.thiet-lap-container {
    max-width: 100%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.thiet-lap-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.thiet-lap-section {
    margin-bottom: 30px;
}

.thiet-lap-section-title {
    font-size: 20px;
    margin-bottom: 15px;
    color: #333;
}

.thiet-lap-field {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}

.thiet-lap-field label {
    flex: 0 0 180px;
    font-size: 16px;
    color: #333;
}

.thiet-lap-field input[type='text'],
.thiet-lap-field input[type='number'] {
    flex: 1;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.thiet-lap-field input[type='checkbox'] {
    margin-right: 10px;
}

.thiet-lap-money-values {
    display: flex;
    gap: 10px;
}

.thiet-lap-money-values button {
    background-color: #2a3e83;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.thiet-lap-money-values button:hover {
    background-color: #1d2a5b;
}

.thiet-lap-checkbox-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.thiet-lap-checkbox-group label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 14px;
}

.thiet-lap-field-group {
    display: flex;
    flex-wrap: wrap;
}

.thiet-lap-field-group .thiet-lap-field {
    flex: 1 1 45%;
    margin-right: 10px;
}

.thiet-lap-field-group .thiet-lap-field:nth-child(even) {
    margin-right: 0;
}

.thiet-lap-button {
    background-color: #2a3e83;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.thiet-lap-button:hover {
    background-color: #1d2a5b;
}

@media (max-width: 480px) {
    .thiet-lap-money-values {
        flex-wrap: wrap;
    }
}