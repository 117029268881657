@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--font-family);
}

:root {
    --blue: #287bff;
    --white: #fff;
    --grey: #f5f5f5;
    --black1: #222;
    --black2: #999;
}

body {
    min-height: 100vh;
    overflow-x: hidden;
}

.container {
    position: relative;
    width: 100%;
}


/* main */

.main {
    position: absolute;
    width: calc(100% - 300px);
    left: 300px;
    min-height: 100vh;
    background: var(--white);
    transition: 0.5s;
    z-index: 0;
}

.main.active {
    width: calc(100% - 80px);
    left: 80px;
}

.btn {
    position: relative;
    padding: 5px 10px;
    background: var(--blue);
    text-decoration: none;
    color: var(--white);
    border-radius: 6px;
}


/*now, make it responsive design */

@media (max-width: 991px) {
    .main {
        width: 100%;
        left: 0;
    }
    .main.active {
        left: unset !important;
    }
}

@media (max-width: 768px) {
    .details {
        grid-template-columns: repeat(1, 1fr);
    }
    .recentOrders {
        overflow-x: auto;
    }
    .status.inprogress {
        white-space: nowrap;
    }
    .container {
        position: static;
    }
}

@media (max-width: 480px) {
    .user {
        min-width: 40px;
    }
    .toggle {
        z-index: 10001;
    }
    .main.active .toggle {
        position: fixed;
        right: 0;
        left: initial;
        color: var(--white);
    }
}