.details {
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 30px;
    /* margin-top: 10px; */
}

.details .recentOrders {
    position: relative;
    display: grid;
    min-height: 500px;
    background: var(--white);
    padding: 20px;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.cardHeader h2 {
    font-weight: 600;
    color: var(--blue);
}

.details table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.details table thead td {
    font-weight: 600;
}

.details .recentOrders table tr {
    color: var(--black1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.details .recentOrders table tr:last-child {
    border-bottom: none;
}

.details .recentOrders table tbody tr:hover {
    background: var(--blue);
    color: var(--white);
}

.details .recentOrders table tr td {
    padding: 10px;
}

.details .recentOrders table tr td:last-child {
    text-align: end;
}

.details .recentOrders table tr td:nth-child(2) {
    text-align: end;
}

.details .recentOrders table tr td:nth-child(3) {
    text-align: center;
}

.status.delivered {
    padding: 2px 4px;
    background: #8de02c;
    color: var(--white);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.status.pending {
    padding: 2px 4px;
    background: #f9ca3f;
    color: var(--white);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.status.return {
    padding: 2px 4px;
    background: #f00;
    color: var(--white);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.status.inprogress {
    padding: 2px 4px;
    background: #1795ce;
    color: var(--white);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.recentCustomers {
    position: relative;
    display: grid;
    min-height: 500px;
    padding: 20px;
    background: var(--white);
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}

.recentCustomers .imgBx {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.recentCustomers .imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.recentCustomers table tr td {
    padding: 12px 10px;
}

.recentCustomers table tr td h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2em;
}

.recentCustomers table tr td h4 span {
    font-size: 14px;
    color: var(--black2);
}

.recentCustomers table tr:hover {
    background: var(--blue);
    color: var(--white);
}

.recentCustomers table tr:hover td h4 span {
    color: var(--white);
}

@media (max-width: 991px) {
    .graphBox {
        grid-template-columns: 1fr;
        height: auto;
    }

    .cardBox {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .details {
        grid-template-columns: repeat(1, 1fr);
    }

    .recentOrders {
        overflow-x: auto;
    }

    .status.inprogress {
        white-space: nowrap;
    }

    .divtablexuakho {
        width: 100%;
        overflow-x: auto;
        height: 700px;

        table {
            width: 100%;

            thead {
                position: sticky;
                top: 0;
                z-index: 100;
                background-color: white;
            }
        }
    }

    .detailsnhap .recentOrdersnhap {
        padding: 5px !important;
        margin-top: 50px !important;
    }
}

@media (max-width: 480px) {
    .cardBox {
        grid-template-columns: repeat(1, 1fr);
    }

    .cardHeader h2 {
        font-size: 20px;
    }

}