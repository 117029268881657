.app-container1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #a8d4ff;
    padding-top: 5%;
  }
  
  .breadcrumbs {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #005fc4;
    padding-bottom: 15px;
    padding-left: 15px;
  }
  
  .breadcrumb-link {
    text-decoration: none;
    color: #007bff;
    margin-right: 5px;
  }
  
  .breadcrumb-link:hover {
    text-decoration: underline;
  }
  
  .breadcrumb-separator {
    margin-right: 5px;
    color: #6c757d;
  }
  
  .breadcrumb-current {
    color: #6c757d;
  }
  
  .search-tq {
    display: flex;
    align-items: center;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: #fff;
    overflow: hidden;
  }
  
  .search-input-tq {
    border: none;
    padding: 8px 12px;
    font-size: 14px;
    outline: none;
  }
  
  .search-button-tq {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    background-color: #007bff;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .search-button:hover {
    background-color: #0056b3;
  }
  
  .search-icon {
    font-size: 16px;
  }
  