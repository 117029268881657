            @import 'normalize.css';
             :root {
                --primary: #fe2c55;
                //Default Layout
                --default-layout-header-height: 60px;
                --default-layout-with: 1150px;
                --default-layout-horizontal-spacer: 24px;
                --font-family: 'TikTokFont', sans-serif;
                --black: #000;
                --text-color: #161823;
                --white: #fff
            }
            
            @font-face {
                font-family: TikTokFont;
                src: url('../../assets/fonts/TikTokFont-Regular.woff2');
                font-weight: 400;
            }
            
            @font-face {
                font-family: TikTokFont;
                src: url('../../assets/fonts/TikTokFont-Semibold.woff2');
                font-weight: 600;
            }
            
            @font-face {
                font-family: TikTokFont;
                src: url('../../assets/fonts/TikTokFont-Bold.woff2');
                font-weight: 700;
            }
            
            @font-face {
                font-family: TikTokDisplayFont;
                src: url('../../assets/fonts/TikTokDisplayFont-Bold.woff2');
                font-weight: 700;
            }