.transfer-orders {
    padding: 10px;

    h2 {
        margin-bottom: 20px;
    }

    table {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
            border: 1px solid #ddd;
            padding: 10px;
            text-align: left;
        }

        th {
            background-color: #f4f4f4;
        }

        tbody tr:hover {
            background-color: #f9f9f9;
        }

        .confirm-btn {
            background-color: #28a745;
            color: white;
            border: none;
            padding: 5px 10px;
            cursor: pointer;
            border-radius: 4px;

            &:hover {
                background-color: #218838;
            }
        }
    }
}

.filter-container {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.date-picker {
    display: flex;
    flex-direction: row;
}

.search-btn {
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}

.search-btn:hover {
    background-color: #0056b3;
}

.filter-container {
    display: flex;
    align-items: center;
    gap: 15px;
    font-family: Arial, sans-serif;
    font-size: 14px;
}

.dropdown-container,
.date-picker {
    display: flex;
    align-items: center;
    gap: 5px;
}

label {
    font-weight: bold;
    white-space: nowrap;
}

select,
input[type="date"] {
    padding: 12px 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.search-btn {
    background-color: #007bff;
    color: white;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.search-btn:hover {
    background-color: #0056b3;
}

.divdate1 {
    display: flex;
    align-items: center;
    gap: 10px;
}
.orders-section {
    min-height: 850px;
}

@media screen and (max-width: 46.1875em) {
    .filter-container {
        flex-direction: column;
        align-items: start;
    }

    .divdate1 {
        align-items: start;
        width: 100%;
    }

    .date-picker {
        flex-direction: column;
        align-items: start;
        gap: 5px;
        flex: 1;
    }

    .search-btn {
        width: 100%;
    }

    .transfer-orders {
        margin-top: 80px;
    }

    .orders-section {
        width: 100%;
        overflow-x: auto;
        height: 600px !important;
    }
}