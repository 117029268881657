/* Global Styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Arial", sans-serif;
  background-color: #f4f7f9;
  color: #333;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

/* Header Section */
.header {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  background-color: #ffffff;
  padding: 15px 30px;
  border-bottom: 1px solid #e0e0e0;
}

.logo-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo-icon {
  font-size: 1.8rem;
  color: #007bff;
}

.header-actions {
  flex-grow: 1;
  justify-content: center;
}

.save-draft-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.store-name {
  font-size: 1rem;
  color: #333;
}

.iconbanhang {
  font-size: 1.5rem;
  color: #007bff;
  cursor: pointer;
}

.row {
  display: flex;
  flex-grow: 1;
  padding: 20px;
  gap: 20px;
}

.column {
  gap: 10px;
}

.left-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Phân bổ nội dung đều giữa các phần */
  height: 100%;
  /* Chiếm toàn bộ chiều cao */
}

.head {
  flex: 1;
  /* Phần này linh hoạt chiếm không gian */
  padding: 20px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.prd {
  background-color: #ffffff;
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  /* Cho phép cuộn khi danh sách sản phẩm dài */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.right-column {
  flex: 1;
  /* Takes up less space */
}

.left-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.search-bar-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.search-bar {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #f1f3f4;
  padding: 10px;
  border-radius: 5px;
}

.search-input {
  flex-grow: 1;
  padding: 10px;
  border: none;
  background-color: white;
  outline: none;
  border-radius: 4px;
}

.quantity-input {
  width: 50px;
  padding: 5px;
  text-align: center;
}

.search-icon,
.barcode-icon,
.staff-icon,
.price-tag-icon {
  font-size: 1.5rem;
  color: #888;
}

.search-options {
  display: flex;
  gap: 10px;
}

.search-option-btn {
  background-color: #e0e0e0;
  padding: 8px 15px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.empty-product-message {
  text-align: center;
  margin: 20px 0;
}

.empty-product-img {
  height: 140px;
  width: 120px;
  margin-bottom: 10px;
}

.empty-product-message p {
  font-size: 1rem;
  color: #888;
}

.choose-product-btn,
.import-product-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin: 10px 5px;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  padding: 20px;

}

.product-card {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  height: 100px;
  width: 200px;
  text-align: center;
  border: 1px solid #e0e0e0;
  font-size: 1rem;
  font-weight: bold;
  padding: 20px;
}

.product-card:hover {
  background-color: #007bff;
  color: white;
}

/* Checkout Section */
.checkout-section {
  background-color: #fff;
  padding-top: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding-right: 10px;
  padding-left: 5px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.checkout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 100px;

}

.store-btn {
  background-color: #e0e0e0;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
}

.customer-input-section {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #f1f3f4;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.customer-input {
  flex-grow: 1;
  padding: 10px;
  border: none;
  background: none;
  outline: none;
}

.checkout-summary {
  margin-bottom: 20px;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.payment-method {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  padding: 10px;
  margin-bottom: 20px;


}

.inputBanHang {
  height: 50px;
  font-size: 18px;
  border: none;
  border-bottom: 1px solid rgb(192, 192, 192);
  cursor: pointer;
  background-color: white;
  outline: none;
  text-align: right;

  &:hover {
    border-bottom-color: var(--blue);
  }
}

.border-bottom {
  height: 50px;
  font-size: 18px;
  border: none;
  border-bottom: 2px solid var(--blue);
  cursor: pointer;
  background-color: white;
  outline: none;
  text-align: right;

  &:hover {
    border-bottom-color: var(--blue);
  }
}


.additional-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.notes-input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.cash-suggestions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 5px;
}

.cash-suggestions button {
  background-color: #e0e0e0;
  padding: 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  flex: 1;
}

.checkout-actions {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.pay-btn,
.save-btn {
  
  color: white;
  padding: 10px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  height: 68px;
}

.pay-btn {
  background-color: #007bff;
  flex: 1;
}
.save-btn{
  background-color: #ff4f4c;
}

.pay-btn:hover{
  background-color: #0056b3;
}

.save-btn:hover {
  background-color: #b61d1b;
}

.selected-products-container {
  margin: 20px;
  font-family: Arial, sans-serif;

  h3 {
    margin-bottom: 10px;
    color: #333;
  }

  .product-table {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr {
        background-color: #e8eaf6;
        text-align: left;

        th {
          padding: 10px;
          font-size: 14px;
          color: #555;
        }
      }
    }

    tbody {
      tr {
        background-color: #eef1ff;
        transition: background-color 0.2s;

        &:hover {
          background-color: #d6dbf5;
        }

        td {
          padding: 10px;
          font-size: 14px;
          color: #333;

          &:last-child {
            text-align: center;
          }
        }
      }

      .remove-btn {
        background-color: #ff5252;
        color: #fff;
        border: none;
        border-radius: 3px;
        padding: 5px 10px;
        cursor: pointer;

        &:hover {
          background-color: #ff1744;
        }
      }
    }
  }
}

.imei-list-row {
  font-size: 0.9rem;
  color: #555;
  background-color: #f9f9f9;
  padding: 5px;
}

.selected-imeis {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.imei-btn {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.imei-btn:hover {
  background-color: #0056b3;
}

.TaiKhoanThu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  padding: 20px;

  button {
    height: 50px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-bottom: 1px solid rgb(192, 192, 192);
    cursor: pointer;
    background-color: white;
    width: 100%;

    &:hover {
      border-bottom-color: var(--blue);
    }
  }
}

.divInhoadon {
  display: flex;
  align-items: center;
  gap: 10px;
}

.iconInhoadon {
  font-size: 100px;
  color: var(--blue);
  width: 40px;
  transition: all 0.3s ease;
  height: 50px;
}

.iconInhoadon.on {
  color: var(--blue);
  transform: scale(1.1);
}

.iconInhoadon.off {
  color: var(--blue);
  transform: scale(1);
}

.inputbanhang {
  border: none;
  padding: 10px;
  outline: none;
  border-radius: 5px;
  transition: all 0.3s ease;
}


.select-serial-btn {
  padding: 5px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
  transition: background-color 0.3s ease,
    transform 0.3s ease;

  &:hover {
    background-color: #dfdfdf;
    transform: scale(1.05);
  }
}

.iconaddkhachhang {
  font-size: 1.5rem;
  color: white;
  background-color: var(--blue);
  padding: 5px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  border: 5px solid #ADD8E6;
  cursor: pointer;
  transition: box-shadow 0.3s ease,
    transform 0.3s ease;

  &:hover {
    box-shadow: 0 0 0 10px #e6e6e6;
    /* Thêm hiệu ứng vòng ngoài */
    transform: scale(1);
  }
}

.divgoiytienmat {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  div {
    font-weight: 700;
  }

  hr {
    border: 0;
    flex: 1;
    height: 1px;
    background: rgba(0, 0, 0, 0.12);
    transition: background .3s,
      opacity .3s;
  }
}