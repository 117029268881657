.trbaocaokho {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    background-color: white;
}

.trbaocaosanphamkho {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    background-color: white;
}

.thbaocaokho {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    border-collapse: collapse;
    font-weight: bold;

}

.baocaokho-container {
    margin-top: 80px;
    padding: 10px;
}

.date-filter {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.date-filter label {
    margin-right: 10px;
    font-weight: bold;
}

.date-filter input {
    padding: 5px;
    font-size: 14px;
    margin-right: 10px;
}



.btn-get-data {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}