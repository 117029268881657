.tong-dai-tu-van {
  // position: fixed;

  background: #f7f9fb;
  width: 20%;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px 10px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* Nội dung ở trên */
  align-items: center;
  /* Căn giữa nội dung */
  float: right;
  margin-right: 150px;
}

.hotline-button {
  margin-top: auto;
  /* Đẩy nút xuống cuối */
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.hotline-button:hover {
  background-color: #0056b3;
}

@media screen and (max-width: 768px) {
  .tong-dai-tu-van {
    display: none;
  }
}