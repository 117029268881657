.modal-overlay-imel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content-imel {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;

  justify-items: center;
}

.modal-content-imel h1 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333333;
}

.modal-content-imel p {
  font-size: 18px;
  color: #555555;
  margin-bottom: 20px;
}

.modal-content-imel canvas {
  display: block;
  margin: 0 auto 20px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
}

.modal-content-imel button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  margin: 10px 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal-content-imel button:first-of-type {
  background-color: #4CAF50;
  color: #fff;
}

.modal-content-imel button:first-of-type:hover {
  background-color: #307c34;
}

.modal-content-imel button:last-of-type {
  background-color: #f44336;
  color: #fff;
}

.modal-content-imel button:last-of-type:hover {
  background-color: #e53935;
}
