.inputSoTienTcTM,
.inputDienGiaiTcTM,
.selectMucThu {
    width: 100%;
    padding: 5px;
    border: none;
    outline: none;
    font-size: 15px;
}

.inputSoTienTcTM {
    text-align: end;
}

.btnDeleterow {
    border: none;
    background: white;
    font-size: 20px;
    color: red;
    cursor: pointer;
}
.btnaddskutest1 {
    display: flex;
    height: 40px;
    align-items: center;
    width: 40px;
    padding: 10px;
    border: 1px solid rgb(204, 204, 204);
    background-color: rgb(243, 243, 243);
    justify-content: center;
    cursor: pointer;
    &:hover{
        background-color: rgb(228, 228, 228);
    }
    .iconaddtest1 {
        width: 10px;
    }
}