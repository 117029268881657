/* Phân trang container */

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.detailsnhapkho .recentOrdersnhapkho {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 400px;
    background: var(--white);
    padding: 20px;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    margin-top: 30px;
}

.table-tong-cong {
    background-color: #ffffff;
}

.detailsnhapkho {
    width: 100%;
    padding: 10px;
    font-family: var(--font-family);
}

/* Nút phân trang */

.pagination button {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px 15px;
    margin: 0 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
}


/* Nút phân trang khi hover */

.pagination button:hover {
    background-color: #e0e0e0;
}


/* Nút phân trang khi trang hiện tại */

.pagination button.active {
    background-color: #007bff;
    color: #fff;
    border: 1px solid #007bff;
}


/* Nút phân trang bị vô hiệu hóa */

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.selectedrow {
    background-color: #e7e7e7;
    /* Màu nền khác để đánh dấu */
    transition: background-color 0.3s ease;
}

.divtablenhapkho {
    overflow-x: auto;
    position: relative;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;
    max-width: 100%;

    table {
        width: 100%;
        thead {
            position: sticky;
            top: 0;
            background-color: white;
            z-index: 10;
            will-change: transform;
        }
    }
}