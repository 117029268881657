.cardBox {
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    z-index: 0;
    margin-top: 40px;
}

.cardBox .card {
    position: relative;
    background: var(--white);
    padding: 30px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    z-index: 0;
}

.cardBox .card .numbers {
    position: relative;
    font-weight: 500;
    font-size: 2.5em;
    color: var(--blue);
}

.cardBox .card .cardName {
    color: var(--black2);
    font-size: 1.1em;
    margin-top: 5px;
}

.cardBox .card .iconBx {
    font-size: 3.5em;
    color: var(--black2);
}

.cardBox .card:hover {
    background: var(--blue);
}

.cardBox .card:hover .numbers,
.cardBox .card:hover .cardName,
.cardBox .card:hover .iconBx {
    color: var(--white);
}


/* charts */

.graphBox {
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 30px;
    margin-top: 40px;
    min-height: 200px;
}

.graphBox .box {
    position: relative;
    background: #fff;
    padding: 20px;
    width: 100%;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    align-items: center;
}

.details1 {
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 30px;
    /* margin-top: 10px; */
}

.details1 .recentOrders1 {
    position: relative;
    display: unset !important;
    min-height: 500px;
    background: var(--white);
    padding: 20px;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    padding-bottom: 50px !important;
    height: 100%;
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.cardHeader h2 {
    font-weight: 600;
    color: var(--blue);
}

.details1 table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    height: 100%;
}

.details1 table thead td {
    font-weight: 600;
}

.details1 .recentOrders1 table tr {
    color: var(--black1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.details1 .recentOrders1 table tr:last-child {
    border-bottom: none;
}

.details1 .recentOrders1 table tbody tr:hover {
    background: var(--blue);
    color: var(--white);
}

.details1 .recentOrders1 table tr td {
    padding: 10px;
}

.details1 .recentOrders1 table tr td:last-child {
    text-align: end;
}

.details1 .recentOrders1 table tr td:nth-child(2) {
    text-align: end;
}

.details1 .recentOrders1 table tr td:nth-child(3) {
    text-align: center;
}

.status.delivered {
    padding: 2px 4px;
    background: #8de02c;
    color: var(--white);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.status.pending {
    padding: 2px 4px;
    background: #f9ca3f;
    color: var(--white);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.status.return {
    padding: 2px 4px;
    background: #f00;
    color: var(--white);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.status.inprogress {
    padding: 2px 4px;
    background: #1795ce;
    color: var(--white);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.recentCustomers {
    position: relative;
    display: grid;
    min-height: 500px;
    padding: 20px;
    background: var(--white);
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}

.recentCustomers .imgBx {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.recentCustomers .imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.recentCustomers table tr td {
    padding: 12px 10px;
}

.recentCustomers table tr td h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2em;
}

.recentCustomers table tr td h4 span {
    font-size: 14px;
    color: var(--black2);
}

.recentCustomers table tr:hover {
    background: var(--blue);
    color: var(--white);
}

.recentCustomers table tr:hover td h4 span {
    color: var(--white);
}

@media (max-width: 991px) {
    .graphBox {
        grid-template-columns: 1fr;
        height: auto;
    }
    .cardBox {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .details1 {
        grid-template-columns: repeat(1, 1fr);
    }
    .recentOrders1 {
        overflow-x: auto;
    }
    .status.inprogress {
        white-space: nowrap;
    }
}

@media (max-width: 480px) {
    .cardBox {
        grid-template-columns: repeat(1, 1fr);
    }
    .cardHeader h2 {
        font-size: 20px;
    }
}