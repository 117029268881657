.divnhomkhachhang {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;

    select {
        outline: none;
        cursor: pointer;
    }
}

.labelnhomkhachHang {
    position: static !important;
}