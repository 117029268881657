#qr-reader {
    width: 100%;
    height: 100%;
    position: relative;
}

#qr-shaded-region {
    position: absolute;
    border-width: 125px 83px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.48);
    box-sizing: border-box;
    inset: 0px;
}

.qr-box {
    position: absolute;
    border: 2px dashed red;
    width: 300px;
    height: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.scan-line {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: red;
}

#result {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-size: 1.5rem;
    color: white;
}

// #qr-reader__dashboard_section_csr {
//     span{
//         display: none;
//     }
// }
