.tdEdit{
    font-weight: bold;
}

.btnDeleteSp{
    border: none;
    background-color: white;
    color: red;
    font-size: 20px;
}
