.divaddkho {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.fullscreen-loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5); /* Màu nền mờ */
    z-index: 9999; /* Đảm bảo overlay hiển thị trên tất cả */
  }
  
.divtenkho,
.divdiachikho {
    width: 100%;
    text-align: center;
    position: relative;
    margin-top: 30px;
    padding: 10px;
}

.tenkho,
.diachi {
    width: 100%;
    padding: 10px;
    height: 50px;
    font-size: 16px;
    border: 1px solid #ccc;
    background-color: #f5f5f5;
    font-family: var(--font-family);
    font-weight: bold;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
    border-radius: 15px;
    
}

.tenkho:focus,
.diachi:focus {
    border-color: #ccc;
}

.label {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    font-size: 16px;
    color: #aaa;
    transition: all 0.3s ease;
    pointer-events: none;
}

.diachi:focus+.label,
.diachi:not(:placeholder-shown)+.label,
.tenkho:focus+.label,
.tenkho:not(:placeholder-shown)+.label {
    top: -10px;
    left: 10px;
    font-size: 12px;
    color: #333;
}

.btnaddkho {
    width: 100%;
    height: 50px;
    font-size: 16px;
    border: 1px solid #ccc;
    background-color: var(--blue);
    font-family: var(--font-family);
    color: #fffefe;
    font-weight: bold;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
    &:hover {
        background-color: #0063c0;
    }
}

.btnhuyaddkho {
    width: 100%;
    height: 50px;
    font-size: 16px;
    border: 1px solid #ccc;
    background-color: #ff4747;
    color: #fffefe;
    font-family: var(--font-family);
    font-weight: bold;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
    &:hover {
        background-color: #c20202;
    }
}

.input-error {
    border-color: red;
}

.error {
    color: red;
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    text-align: center;
}