    .scanner-line {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        /* Độ dày của vạch quét */
        background-color: red;
        /* Màu của vạch quét */
        animation: scan 2s linear infinite;
        /* Thời gian và kiểu quét */
    }

    .wrapper1 {
        display: flex;
        position: relative;
        width: 100%;
        height: 120px;
        overflow: hidden;
        align-items: center;
    }

    @keyframes scan {
        0% {
            top: 0;
        }

        50% {
            top: calc(100% - 2px);
            /* Di chuyển từ trên xuống dưới */
        }

        100% {
            top: 0;
            /* Quay trở lại vị trí ban đầu */
        }
    }

    .divvideo {
        position: relative;
        display: inline-block;
        width: 375px;
        height: 200px;
        box-sizing: content-box;
    }

    video {
        width: 100% !important;
        height: 100%;
        object-fit: cover;
        /* Phóng to vừa khung */
    }

    .video.thanhcong {
        border: 2px solid rgb(0, 180, 0);
    }

    .divButtonImel {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        gap: 10px;
    }

    .btnAddImelist {
        padding: 10px;
        border: none;
        width: 150px;
        background-color: var(--blue);
        color: var(--white);
        border-radius: 5px;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }

    .btnHuyImelist {
        padding: 10px;
        border: none;
        width: 100px;
        background-color: rgb(254, 44, 44);
        color: var(--white);
        border-radius: 5px;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }

    #qr-reader {
        display: inline-block;
    }

    @media screen and (max-width: 46.1875em) {
        .widthphantu {
            width: 100px !important;
            white-space: normal !important;
            padding: 5px !important;
            overflow: hidden;
        }

        .widthphantu1 {
            max-width: 30px;
            overflow: hidden;

            .divDeleterow {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .widthrieng{
            max-width: 70px !important;
            .btnDeleterow{
                width: 100%;
            }
        }

        .results {
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 300px;
            overflow-y: auto;
            box-sizing: border-box;

            table {
                display: block;
                width: 100%;
                border-collapse: collapse;
                border-radius: 5px;
                height: 100%;

                thead {
                    display: table;
                    width: 100%;
                    background-color: white;

                }

                tbody {
                    display: table;
                    width: 100%;
                }

                tr {
                    display: flex;
                }

                th,
                td {
                    padding: 10px;
                    text-align: center;
                    border: 1px solid #ccc;
                    flex: 1;
                    width: 30%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

            }
        }

    }