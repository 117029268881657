                .doanh-thu-layout {
                    padding: 20px;
                    font-family: Arial, sans-serif;
                    margin-top: 80px;
                }

                h1 {
                    text-align: center;
                    font-size: 24px;
                    margin-bottom: 20px;
                }

                .filter-section {
                    display: flex;
                    margin-bottom: 20px;
                    align-items: start;
                    gap: 10px;

                    .filter-group {
                        display: flex;
                        align-items: center;

                        label {
                            margin-right: 10px;
                            font-weight: bold;
                        }

                        select {
                            padding: 5px;
                            font-size: 14px;
                        }
                    }

                    .date-filter {
                        display: flex;
                        align-items: center;

                        label {
                            margin-right: 10px;
                            font-weight: bold;
                        }

                        input {
                            padding: 5px;
                            font-size: 14px;
                            margin-right: 10px;
                        }

                        .btn-get-data {
                            padding: 5px 10px;
                            background-color: #007bff;
                            color: white;
                            border: none;
                            border-radius: 5px;
                            cursor: pointer;
                        }

                        .btn-get-data:hover {
                            background-color: #0056b3;
                        }
                    }
                }

                .table-section {
                    margin-bottom: 20px;

                    table {
                        width: 100%;
                        border-collapse: collapse;
                        font-size: 14px;

                        th,
                        td {
                            border: 1px solid #ddd;
                            padding: 8px;
                            text-align: center;
                        }

                        th {
                            background-color: #f2f2f2;
                            font-weight: bold;
                        }

                        tbody tr:nth-child(even) {
                            background-color: #f9f9f9;
                        }

                        tbody tr:hover {
                            background-color: #f1f1f1;
                        }

                        tbody tr td:first-child {
                            text-align: left;
                        }

                        .highlight-row {
                            background-color: #ffdddd;
                        }
                    }
                }

                .actions {
                    text-align: right;

                    .btn-print,
                    .btn-export {
                        padding: 10px 20px;
                        margin-left: 10px;
                        background-color: #28a745;
                        color: white;
                        border: none;
                        border-radius: 5px;
                        cursor: pointer;
                    }

                    .btn-print:hover,
                    .btn-export:hover {
                        background-color: #218838;
                    }
                }

                @media (max-width: 480px) {
                    .filter-section {
                        display: flex;
                        flex-direction: column;
                    }

                    .doanh-thu-layout {
                        margin-top: 50px;
                        padding-top: 20px;
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    .filter-section .date-filter input {
                        font-size: 16px;
                    }

                    .btn-get-data {
                        width: 100%;
                    }
                }