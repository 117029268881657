.btnxoaspCheck{
    margin-left: 10px;
    border: none;
    background-color: var(--blue);
    color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    &:hover{
        background-color: rgb(0, 66, 151);
    }
}