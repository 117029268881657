/* ModalBig.scss */

.ModalBig-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.ModalBig-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    position: absolute; // Đổi từ relative sang absolute
    overflow: hidden;
    cursor: grab; // Thay đổi con trỏ mặc định
}

.ModalBig-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    font-weight: bold;
}

@media screen and (max-width: 46.1875em) {
    .ModalBig-content {
        background: white;
        padding: 20px;
        border-radius: 8px;
        width: 100%;
        height: 100%;
        max-width: 46.1875em;
        position: absolute; // Đổi từ relative sang absolute
        overflow: hidden;
        overflow-y: auto;
    }
}
