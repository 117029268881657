.modal-data-screen {
  .open-modal-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
}
.modal-body {
  max-height: 300px; /* Chiều cao tối đa */
  overflow-y: auto; /* Cho phép cuộn dọc */
}
.modal-header1 {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .product-icon {
    width: 50px;
    margin-right: 10px;
  }

  h2 {
    margin: 0;
    font-size: 20px;
  }

  p {
    margin: 0;
    font-size: 15px;
    color: gray;
  }
}

.size-selector {
  display: flex;
  gap: 10px;

  .size-btn {
    background-color: #f0f0f0;
    padding: 10px;
    border: none;
    border-radius: 20px;
    margin-bottom: 10px;
    cursor: pointer;
    &.available {
      background-color: #007bff;
      color: white;
    }
  }
}

.modal-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;

  th,
  td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
}

.modal-footer {
  position: absolute;
  bottom: 20px; /* Khoảng cách từ đáy modal */
  right: 20px; /* Khoảng cách từ phải modal */
  display: flex;
  gap: 10px; /* Khoảng cách giữa các nút */
  
  .agree-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .close-btn {
    background-color: #f0f0f0;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
}
.selected1 {
  font-size: 14px;
}
/* Modal.scss */

.modal-overlay-banhang {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content-banhang {
  background: white;
  padding: 20px;
  padding-bottom: 80px; /* Thêm khoảng trống cho footer */
  border-radius: 8px;
  width: 80%;
  max-width: 850px;
  height: 50%;
  position: relative;
  overflow: hidden;
  cursor: grab;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
}
.tooltip-target-wrapper {
  display: block;
  width: 100%;
  text-align: center;
}
.size-btn.selected1 {
  background-color: #007bff;
  color: white;
}
.no-selection-message {
  text-align: center; /* Căn giữa nội dung */
  margin-top: 20px; /* Khoảng cách từ trên xuống */
}

.no-selection-image {
  width: 100px; /* Chiều rộng của hình ảnh */
  height: auto; /* Chiều cao tự động để giữ tỷ lệ */
  margin-bottom: 10px; /* Khoảng cách giữa hình ảnh và văn bản */
}
@media screen and (max-width: 46.1875em) {
  .modal-content-banhang {
    background: white;
    padding: 20px;
    padding-bottom: 80px;
    border-radius: 8px;
    width: 100%;
    max-width: 46.1875em;
    position: relative;
    overflow: hidden;
  }
}
.clickable {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}
