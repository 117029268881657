.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  width: 650px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.modal-header {
  padding: 16px;
  cursor: move;
  display: flex;
  justify-content: space-between;
  /* Căn giữa nội dung và nút đóng */
  align-items: center;
}

.modal-close {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.2em;
  color: #888;

  &:hover {
    color: #555;
  }
}

.modal-body {
  padding: 20px;
  font-size: 1em;
  color: #333;
}

@media screen and (max-width: 46.1875em) {
  .modal-content {
    max-height: 100%;
    margin: 20px;
  }

  .modal-close {
    font-size: 20px;
  }
}