
.blog-detail-container{
  
    grid-row: auto;
}
.blog-detail{
    padding-left: 10%;
    padding-right:30%;
    h1{
        margin-bottom: 1rem;
    }
    p{
        margin-bottom: 1rem;
    }
}

.parent-container{
    padding-left: 220px;
    position: sticky;
    top: 10%;
    
}
@media screen and (max-width: 768px) {
    .blog-detail{
        padding-left: 5%;
        padding-right: 5%;
        
        h1{
            margin-bottom: 1rem;
        }
        p{
            margin-bottom: 1rem;
        }
    }
  }
  