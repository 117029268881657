html,
body {
  margin: 0;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: var(--font-family);
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #75d2fd, #69d9fc);
  border-radius: 10px;
}

