/* Tổng thể giao diện */
.thu-no-container {
    font-family: "Arial", sans-serif;
    padding: 20px;
    background-color: #f9f9f9;
    color: #333;
}

/* Tabs tiêu đề */
.header1 {
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
}

/* Tabs */
.tabs {
    display: flex;
    gap: 20px;
    font-size: 16px;
    margin-bottom: 15px;
    cursor: pointer;
}

.tabs span {
    padding: 10px 20px;
    border-bottom: 3px solid transparent;
    transition: all 0.3s ease-in-out;
    color: #555;
}

.tabs span:hover {
    color: #007bff;
}

.active-tab {
    color: #007bff !important;
    border-bottom: 3px solid #007bff !important;
    font-weight: bold;
}

/* Nội dung tab */
.tab-content-container {
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Bảng dữ liệu */
.thu-no-table {
    width: 100%;
    margin-bottom: 20px;
    background: white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border-spacing: 0;
}

.thu-no-table th,
.thu-no-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.trthuno {
    height: 46px !important;
}

.thu-no-table th {
    background-color: #f4f6f9;
    font-weight: 700;
    font-size: 14px;
    border: none;
    text-align: center;
    padding: 0px 12px;
}

.thu-no-table td {
    font-size: 14px;
    border: none;
    text-align: center;
}

tr:nth-child(2n) {
    background-color: #f9f9f9;
}

.thu-no-button {
    border-radius: 10px;
    min-width: 100px;
    max-width: 180px;
    height: 36px;
    padding-left: 16px;
    padding-right: 16px;
    font-weight: 700;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    background-color: #50b83c;
    color: #fff;
    text-transform: none;
    text-align: center;
    font-size: 14px;
    border: none;
}

.thu-no-button:hover {
    background-color: #218838;
}


.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.divtablethuno {
    height: 630px;
    width: 100%;
    overflow-y: auto;

    table {
        width: 100%;

        thead {
            position: sticky;
            top: 0;
            z-index: 100;
            background-color: white;
        }
    }
}

/* Footer */
.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
}

.pagination1 {
    position: sticky;
    /* Cố định ở cuối màn hình */
    bottom: 0;
    left: 0;
    width: 100%;
    /* Chiều rộng 100% */
    background-color: #f8f9fa;
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    /* Tạo hiệu ứng bóng đổ */

    button {
        padding: 5px 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #fff;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
            background-color: #e9ecef;
        }

        &:disabled {
            cursor: not-allowed;
            background-color: #f1f1f1;
            color: #aaa;
        }
    }

    select {
        padding: 5px 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    span {
        margin: 0 10px;
        font-size: 14px;
        color: #555;
    }
}

.isetting {
    width: 30px;
    height: 30px;
    background-image: url('../../../public/imgsetting.png');
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 100%;
}