                .detailsnhap {
                    width: 100%;
                    padding: 10px;
                    font-family: var(--font-family);
                }

                .detailsnhap .recentOrdersnhap {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    min-height: 850px;
                    background: var(--white);
                    padding: 20px;
                    border-radius: 20px;
                    margin-top: 30px;
                }

                .headernhap {
                    color: var(--blue);
                    display: flex;
                    align-items: center;
                }



                .btnthemlo {
                    border: none;
                    background-color: var(--blue);
                    color: var(--white);
                    height: 50px;
                    padding: 5px;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    &:hover {
                        background-color: #004dc0;
                    }

                    .iconncc {
                        font-size: 20px;
                    }
                }

                .btnchitietncc {
                    border: none;
                    background-color: var(--blue);
                    color: var(--white);
                    height: 30px;
                    padding: 5px;
                    border-radius: 10px;
                    align-items: center;
                    flex: 1;
                    margin-left: 10px;
                    cursor: pointer;

                    &:hover {
                        background-color: #004dc0;
                    }

                    .iconncc {
                        font-size: 20px;
                    }
                }

                .btncnncc {
                    border: none;
                    background-color: #ff4747;
                    color: var(--white);
                    height: 30px;
                    padding: 5px;
                    border-radius: 10px;
                    align-items: center;
                    flex: 1;
                    margin-left: 10px;
                    cursor: pointer;

                    &:hover {
                        background-color: #c20202;
                    }

                    .iconncc {
                        font-size: 20px;
                    }
                }

                .divncc {
                    flex: 1;
                    display: flex;
                    align-items: center;
                }

                .iconout {
                    font-size: 30px;
                    cursor: pointer;
                    transition: color 0.3s ease;
                }

                .iconout:hover {
                    color: rgb(0, 56, 129);
                    cursor: pointer;
                }

                .tdchucnang {
                    align-items: center;
                }

                .btnAddNhaCungCap {
                    background-color: var(--blue);
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    box-sizing: border-box;
                    color: #fffefe;
                    cursor: pointer;
                    font-family: var(--font-family);
                    font-size: 16px;
                    font-weight: 700;
                    height: 50px;
                    margin-top: 20px;
                    transition: border-color .3s ease;
                    width: 100%;

                    &:hover {
                        background-color: #0063c0;
                    }
                }

                .btnhuyAddNhaCungCap {
                    background-color: #ff4747;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    box-sizing: border-box;
                    color: #fffefe;
                    cursor: pointer;
                    font-family: var(--font-family);
                    font-size: 16px;
                    font-weight: 700;
                    height: 50px;
                    margin-top: 20px;
                    transition: border-color .3s ease;
                    width: 100%;

                    &:hover {
                        background-color: #c20202;
                    }
                }

                @media screen and (max-width: 46.1875em) {
                    .tdchucnang {
                        display: block;
                    }

                    .btnchitietncc {
                        font-size: 15px;
                    }

                    .btncnncc {
                        font-size: 12px;
                        height: unset !important;
                        padding: 5px;
                    }

                    .divnhacungcap {
                        .pagination1 {
                            padding: 0 !important;
                        }
                    }
                }