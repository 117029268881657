.inputaddtest {
    width: 100%;
    max-width: 100%;
    height: 40px;
    font-size: 15px;
    border: none;
    outline: none;
    padding: 5px;
}

.modal-table-test {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;

    td {
        border: 1px solid #ddd;
        text-align: center;
        height: 40px;
        overflow: hidden;
        width: 170px;
        padding: 0;
        white-space: nowrap;
    }

    th {
        border: 1px solid #ddd;
        text-align: center;
        padding: 10px;
    }
}

.btnaddtest,
.btnaddskutest {
    display: flex;
    width: 100%;
    height: 40px;
    background-color: white;
    align-items: center;
    padding: 10px;
    border: none;
    justify-content: space-between;
}

.iconaddtest {
    margin-left: 10px;
    width: 10px;
}

.imel-input-container {
    display: flex;
    gap: 5px;
    align-items: center;
    flex-wrap: wrap;
    max-width: 100%;
}

.imel-tag {
    background-color: #2c3e50;
    color: #fff;
    padding: 3px 5px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.remove-imel-btn {
    background: none;
    border: none;
    color: #fff;
    margin-left: 5px;
    cursor: pointer;
}

.imel-input {
    border: none;
    outline: none;
    padding: 5px;
    flex-grow: 1;
}

.divtableSP {
    height: 150px;
    overflow-y: auto;
}

.tdMasku {
    display: flex;
    align-items: center;
}

.btnaddskutest {
    border: 1px solid rgb(204, 204, 204);
    background-color: rgb(243, 243, 243);
}

.divnhapImel {
    display: flex;
    align-items: center;

}

.btnnhapImel {
    border: none;
    background-color: white;
    font-size: 30px;
    display: none;
}

.tippy-tooltip-content {
    max-height: 200px;
    overflow: auto;
}

@media (max-width: 480px) {
    .divTableSP {
        max-height: 400px;
        overflow-y: auto;
        overflow-x: auto;
    }
    td {
        input {
            font-size: 16px;
        }
    }
    .btnnhapImel {
        display: block;
    }
}