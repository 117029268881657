        .topbar {
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px;
            z-index: 10000;
            position: fixed;
            background-color: #ffff;
            width: calc(100% - 300px);
        }
        
        .topbar.active {
            width: calc(100% - 80px);
        }
        
        .toggle {
            position: relative;
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2.5em;
            cursor: pointer;
        }
        
        .search {
            position: relative;
            width: 400px;
            margin: 0 10px;
        }
        
        .search label {
            position: relative;
            width: 100%;
        }
        
        .search label input {
            width: 100%;
            height: 40px;
            border-radius: 40px;
            padding: 5px 20px;
            padding-left: 40px;
            font-size: 18px;
            outline: none;
            border: 1px solid var(--black2);
        }
        
        .search label .iconsearch {
            position: absolute;
            top: 0;
            left: 0px;
            font-size: 1em;
            cursor: pointer;
        }
        
        .search-button {
            position: absolute;
            right: 0;
            top: -10px;
            height: 40px;
            padding: 0 15px;
            border: none;
            background-color: var(--blue);
            color: white;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            cursor: pointer;
        }

        .search-button:hover {
            background-color: rgb(2, 55, 104);
        }
        .user {
            position: relative;
            width: 500px;
            height: 40px;
            display: flex;
            .optiontk {
                width: 30%;
                height: 100%;
                border: none;
                padding: 5px;
                background-color: #eeeeee;
                border-radius: 5px;
                font-family: var(--font-family);
                margin-left: 5px;
                display: flex;
                align-items: center;
                overflow: hidden;
                img {
                    width: 30px;
                    height: 30px;
                    margin-right: 5px;
                    border-radius: 50%;
                    object-fit: cover;
                    cursor: pointer;
                }
            }
            .help {
                display: flex;
                align-items: center;
                font-size: 20px;
                border: 1px solid #ccc;
                height: 100%;
                margin-left: 5px;
                border-radius: 5px;
                .btnicon {
                    border: none;
                    background: #fffdfd;
                    height: 100%;
                    .iconhelp {
                        border-left: 1px solid #ccc;
                        border-right: 1px solid #ccc;
                        font-size: 20px;
                        color: rgb(0, 36, 83);
                        cursor: pointer;
                        &:hover {
                            color: rgb(0, 0, 0);
                        }
                    }
                }
            }
        }
        
        .divthemkho {
            width: 50px;
            height: 100%;
            margin-right: 5px;
            .btnicon {
                background: var(--blue);
                height: 100%;
                color: #fff;
                border-radius: 5px;
                border: 1px solid var(--blue);
                width: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                    background: rgb(2, 55, 104);
                    border-color: rgb(2, 55, 104);
                    color: #fff;
                }
                .iconhelp {
                    font-size: 20px;
                    color: rgb(255, 255, 255);
                    cursor: pointer;
                }
            }
        }
        
        @media (max-width: 768px) {
            .search {
                display: none;
            }
            .user {
                .option {
                    width: 50%;
                }
                .optiontk {
                    width: 50%;
                    height: 100%;
                }
                .help {
                    display: none;
                }
            }
            .topbar {
                width: 100%;
            }
        }
        .filter-dropdown {
            position: absolute;
            background-color: white;
            border: 1px solid #ccc;
            list-style: none;
            padding: 0;
            margin-top: 5px;
            width: 150px;
            z-index: 1000;
        }

        .filter-dropdown li {
            padding: 10px;
            cursor: pointer;
        }

        .filter-dropdown li:hover {
            background-color: #f0f0f0;
        }
