.btn-themquytien {
    padding: 5px 10px;
    font-size: 15px;
    cursor: pointer;
    background-color: var(--blue);
    color: white;
    border: none;
    height: 50px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.carte-rotate1 {
    transform: rotate(180deg);
}

.caret1 {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #ffffff;
    transition: 0.3s;
}

.action-menu {
    .dropdown-menu1 {
        display: block;
        position: absolute;
        top: 50px;
        /* Tùy chỉnh khoảng cách dưới nút */
        left: 0;
        background: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

        z-index: 1000;
        width: 180px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin-bottom: 5px;
                cursor: pointer;
                padding: 10px;

                &:hover {
                    background: var(--blue);

                    button {
                        color: white;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            button {
                font-size: 15px;
                background: none;
                border: none;
                cursor: pointer;
            }
        }
    }
}

 @media screen and (max-width: 46.1875em) {
     .divtablequytien{
        width: 100%;
        overflow-x: auto;
        table{
            thead{
                // position: sticky;
                top: 0;
                z-index: 100;
                background-color: white;
            }
        }
     }
 }