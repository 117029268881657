.container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    font-family: var(--font-family);
}

.dualscreen1,
.dualscreen2 {
    flex: 1;
    padding: 20px;
}

.dualscreen1 {
    overflow: hidden;
    position: relative;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

.dualscreen1 img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 1s ease-in-out;
    opacity: 0;
}

.dualscreen1 img.active {
    opacity: 1;
}

.divlogin {
    margin-top: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.divemail,
.divpassword {
    width: 500px;
    text-align: center;
    position: relative;
    margin: 10px auto;
}

.divemail1,
.divippass {
    position: relative;
}

.email,
.password {
    width: 100%;
    padding: 10px;
    height: 50px;
    font-size: 16px;
    border: 1px solid #ccc;
    background-color: #f5f5f5;
    font-family: var(--font-family);
    font-weight: bold;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
    border-radius: 15px;
}

.email:focus,
.password:focus {
    border-color: #ccc;
}

.label {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 16px;
    color: #aaa;
    transition: all 0.3s ease;
    pointer-events: none;
}

.email:focus+.label,
.email:not(:placeholder-shown)+.label,
.password:focus+.label,
.password:not(:placeholder-shown)+.label {
    top: -10px;
    left: 10px;
    font-size: 12px;
    color: #333;
}

.eye {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    background: none;
    border: none;
    font-size: 18px;
    color: #686868;
}

.eye:hover {
    color: #505050;
}

.input-error {
    border-color: red;
}

.error {
    color: red;
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    text-align: center;
}

.login-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 500px;
}

.remember-me {
    display: flex;
    align-items: center;
}

.remember-me input {
    margin-right: 5px;
}

.forgot-password a {
    text-decoration: none;
    color: #007bff;
}

.forgot-password a:hover {
    text-decoration: underline;
}

.btnLogin {
    margin-top: 10px;
    font-size: 16px;
    font-family: var(--font-family);
    font-weight: bold;
    height: 45px;
    width: 250px;
    border: 1px solid #ccc;
    background-color: #0070f4;
    color: #f5f5f5;
    border-radius: 15px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    &:hover {
        background-color: #0056b8;
    }
}

.loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #22a6b3;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.divcachkhac {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    width: 250px;
    /* Đặt cùng chiều rộng với nút đăng nhập */
}

.btnfacebook,
.btngoogle {
    flex: 1;
    /* Làm cho các nút chiếm cùng một chiều rộng */
    height: 45px;
    /* Chiều cao giống với nút đăng nhập */
    border-radius: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}

.btnfacebook {
    border: 2px solid rgb(24, 119, 242);
    background: rgb(24, 119, 242);
    margin-right: 10px;
    &:hover {
        background-color: #0056b8;
        border-color: #0056b8;
    }
    .facebook {
        width: 60px;
        height: 25px;
    }
}

.btngoogle {
    border: 2px solid rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 5px;
    &:hover {
        background-color: #d4d4d4;
        border-color: #d4d4d4;
    }
    .google {
        width: 60px;
        height: 25px;
    }
}

.register {
    color: rgb(24, 119, 242);
    cursor: pointer;
    margin-left: 5px;
    &:hover {
        color: #0056b8;
    }
}

.divRegister {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
}

////
/// 
.logopm2 {
    width: 100px;
    height: 100px;
}

.chinhsach {
    width: 800px;
    margin-top: 100px;
    text-align: center;
    a {
        text-decoration: none;
        color: rgb(24, 119, 242);
        &:hover {
            color: #0056b8;
        }
    }
}

svg {
    width: 60px;
    height: 25px
}

// 
@media screen and (max-width: 46.1875em) {
    .container {
        overflow: hidden;
        h1 {
            font-size: 40px;
            color: #fffcfc;
        }
    }
    .dualscreen1 {
        display: none;
    }
    .dualscreen2 {
        height: 100vh;
        background-image: url('../../assets/images/tn1.jpg');
        background-size: cover;
        object-fit: cover;
    }
    .divlogin {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .divemail {
        height: 50px;
        width: 300px;
        .divemail1 {
            width: 100%;
            height: 50px;
            .email {
                height: 50px;
                width: 300px;
                font-size: 15px;
            }
            .email:focus+.label,
            .email:not(:placeholder-shown)+.label {
                opacity: 0;
                visibility: hidden;
                font-size: 0;
            }
        }
    }
    .divpassword {
        height: 50px;
        width: 300px;
        .divippass {
            width: 100%;
            height: 50px;
            .password {
                height: 50px;
                width: 300px;
                font-size: 15px;
            }
            .password:focus+.label,
            .password:not(:placeholder-shown)+.label {
                opacity: 0;
                visibility: hidden;
                font-size: 0;
            }
            .eye {
                position: absolute;
                right: 10px;
                top: 50%;
                font-size: 15px;
            }
        }
    }
    .login-options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        width: 300px;
    }
    .remember-me {
        display: flex;
        align-items: center;
        color: #fff;
    }
    .remember-me input {
        margin-right: 5px;
    }
    .forgot-password a {
        text-decoration: none;
        color: #fff;
    }
    .forgot-password a:hover {
        text-decoration: underline;
    }
    .btnLogin {
        font-size: 15px;
        height: 50px;
        width: 300px;
        /* Chiều rộng cố định cho nút đăng nhập */
        border: 1px solid #ccc;
        background-color: #0070f4;
        color: #f5f5f5;
        border-radius: 15px;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        &:hover {
            background-color: #0056b8;
        }
    }
    .divcachkhac {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        gap: 10px;
        /* Khoảng cách giữa các nút */
        width: 300px;
        /* Chiều rộng giống với nút đăng nhập */
    }
    .btnfacebook,
    .btngoogle {
        height: 50px;
        /* Chiều cao giống với nút đăng nhập */
        width: 100%;
        /* Chiều rộng chiếm toàn bộ không gian còn lại */
        border-radius: 15px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        padding: 0 10px;
        /* Padding ngang để tạo không gian xung quanh nội dung */
    }
    .btnfacebook {
        border: 2px solid rgb(24, 119, 242);
        background: rgb(24, 119, 242);
        &:hover {
            background-color: #0056b8;
            border-color: #0056b8;
        }
    }
    .btngoogle {
        border: 2px solid rgba(0, 0, 0, 0.05);
        background: #ffffff;
        &:hover {
            background-color: #d4d4d4;
            border-color: #d4d4d4;
        }
    }
    .register {
        color: rgb(24, 119, 242);
        cursor: pointer;
        margin-left: 5px;
        &:hover {
            color: #0056b8;
        }
    }
    .divRegister {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;
        .register {
            font-size: 15px;
            color: rgb(2, 221, 250);
            cursor: pointer;
            margin-left: 5px;
            &:hover {
                color: #009eb3;
            }
        }
        .register1 {
            font-size: 15px;
            color: #fffcfc;
        }
    }
    .chinhsach {
        width: 300px;
        margin-top: 0px;
        text-align: center;
        a {
            text-decoration: none;
            color: rgb(2, 221, 250);
            &:hover {
                color: #009eb3;
            }
        }
        h4 {
            font-size: 15px;
            color: #fffcfc;
        }
    }
}